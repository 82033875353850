import { MembershipGroup } from 'src/components/GroupsDataGridCell/GroupsDataGridCell'

export type NarrowTreeProps = {
  selectedGroup: MembershipGroup
  groups: MembershipGroup[]
  isStart?: boolean
}

export const narrowTree = ({
  selectedGroup,
  groups,
  isStart = false,
}: NarrowTreeProps): MembershipGroup[] => {
  const children = groups.filter(
    (group) => group.parentGroup?.id === selectedGroup.id,
  )

  if (isStart) {
    if (children.length === 0 && selectedGroup.parentGroup === null) {
      // if no children and no parent, return only selected group
      return [selectedGroup]
    } else if (children.length === 0 && selectedGroup.parentGroup !== null) {
      // find parent in list
      const parent = groups.find(
        (group) => group.id === selectedGroup.parentGroup?.id,
      )

      // clean parent so children is only the selected group
      const cleanParent = { ...parent, children: [selectedGroup] }
      // return selected group and parent and parent's parent
      return [
        selectedGroup,
        cleanParent,
        ...getTreeUpwards(cleanParent, groups).flat(),
      ]
    } else if (children.length !== 0 && selectedGroup.parentGroup === null) {
      // if children and no parent, return selected group and children
      return [
        selectedGroup,
        ...children
          .map((child) =>
            narrowTree({
              selectedGroup: child,
              groups,
            }),
          )
          .flat(),
      ]
    } else {
      // if children and parent, return selected group, parent and parent's parent all way up
      const parent = groups.find(
        (group) => group.id === selectedGroup.parentGroup?.id,
      )
      // clean parent so children is only the selected group
      const cleanParent = parent
        ? { ...parent, children: [selectedGroup] }
        : null

      return [
        selectedGroup,
        cleanParent,
        ...getTreeUpwards(parent, groups).flat(),
        ...children
          .map((child) =>
            narrowTree({
              selectedGroup: child,
              groups,
            }),
          )
          .flat(),
      ]
    }
  }

  if (children.length === 0) {
    return [selectedGroup]
  }
  return [
    selectedGroup,
    ...children
      .map((child) => narrowTree({ selectedGroup: child, groups }))
      .flat(),
  ]
}

const getTreeUpwards = (
  selectedGroup: MembershipGroup,
  groups: MembershipGroup[],
): MembershipGroup[] => {
  const parent = groups.find(
    (group) => group.id === selectedGroup?.parentGroup?.id,
  )
  if (!parent) return []
  // clean parent so children is only the selected group
  const cleanParent = { ...parent, children: [selectedGroup] }

  if (!cleanParent || cleanParent?.parentGroup === null) {
    return [cleanParent]
  }
  return [cleanParent, ...getTreeUpwards(cleanParent, groups).flat()]
}
