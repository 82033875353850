export const BULK_UPSERT_MEMBERSHIPS_TO_MEMBERSHIP_GROUP = gql`
  mutation BulkUpsertMembershipsToMembershipGroup(
    $membershipIdsToRemove: [Int!]!
    $membershipIdsToAdd: [Int!]!
    $membershipGroupId: Int!
  ) {
    bulkUpsertMembershipsToMembershipGroup(
      membershipIdsToRemove: $membershipIdsToRemove
      membershipIdsToAdd: $membershipIdsToAdd
      membershipGroupId: $membershipGroupId
    ) {
      id
      name
    }
  }
`

export const UPDATE_MEMBERSHIP_GROUP = gql`
  mutation UpdateMembershipGroup(
    $id: Int!
    $input: UpdateMembershipGroupInput!
  ) {
    updateMembershipGroup(id: $id, input: $input) {
      id
      name
    }
  }
`

export const CREATE_MEMBERSHIP_GROUP = gql`
  mutation CreateMembershipGroup($input: CreateMembershipGroupInput!) {
    createMembershipGroup(input: $input) {
      id
      name
    }
  }
`

export const DELETE_MEMBERSHIP_GROUP = gql`
  mutation DeleteMembershipGroup($id: Int!) {
    deleteMembershipGroup(id: $id) {
      id
      name
    }
  }
`
