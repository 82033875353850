import React, { memo } from 'react'

import { Handle, Position } from 'reactflow'

function GroupTreeCustomNodes({ data }) {
  return (
    <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400">
      <div className="flex">
        {data.avatar && (
          <div className="rounded-full w-12 h-12 flex justify-center items-center bg-gray-100">
            {data.avatar}
          </div>
        )}
        <div className="flex ml-2 h-12 justify-center items-center">
          <div className="text-lg font-bold">{data.label}</div>
        </div>
      </div>
      {Position.Top && (
        <Handle
          type="target"
          position={Position.Top}
          className="w-16 !bg-indigo-500 !border-0 rounded-none rounded-t-md"
        />
      )}
      {Position.Bottom && (
        <Handle
          type="source"
          position={Position.Bottom}
          className="w-16 !bg-indigo-500 !border-0 rounded-none rounded-b-md"
        />
      )}
    </div>
  )
}

export default memo(GroupTreeCustomNodes)
