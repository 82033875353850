import { FC, useEffect, useState } from 'react'

import { PlusIcon } from '@heroicons/react/24/outline'
import { Tooltip, Grid2 as Grid } from '@mui/material'
import Stack from '@mui/material/Stack'
import {
  BulkUpsertMembershipsToMembershipGroup,
  BulkUpsertMembershipsToMembershipGroupVariables,
  CreateMembershipGroup,
  CreateMembershipGroupVariables,
  DeleteMembershipGroup,
  DeleteMembershipGroupVariables,
  UpdateMembershipGroup,
  UpdateMembershipGroupVariables,
} from 'types/graphql'
import { useDebounceValue, useMediaQuery } from 'usehooks-ts'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import GroupCard from 'src/components/GroupManagement/GroupCard'
import GroupEditForm, {
  membershipGroupInput,
} from 'src/components/GroupManagement/GroupEditForm'
import GroupTreeView from 'src/components/GroupManagement/GroupTreeView'
import MemberListModal from 'src/components/GroupManagement/MemberListModal'
import {
  Membership,
  MembershipGroup,
} from 'src/components/GroupsDataGridCell/GroupsDataGridCell'
import Button from 'src/components/Library/Button/Button'
import Empty from 'src/components/Library/Empty/Empty'
import IconButton from 'src/components/Library/IconButton'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import {
  BULK_UPSERT_MEMBERSHIPS_TO_MEMBERSHIP_GROUP,
  CREATE_MEMBERSHIP_GROUP,
  DELETE_MEMBERSHIP_GROUP,
  UPDATE_MEMBERSHIP_GROUP,
} from 'src/lib/queries/Settings/GroupManagement/GroupManagement'
import { TreeView } from 'src/lib/svg/SvgComponents'

interface GroupManagementProps {
  membershipGroups: MembershipGroup[]
  memberships: Membership[]
}

const GroupManagement: FC<GroupManagementProps> = ({
  membershipGroups,
  memberships,
}) => {
  const [selectedGroup, setSelectedGroup] = useState<MembershipGroup>(null)
  const [filteredGroups, setFilteredGroups] =
    useState<MembershipGroup[]>(membershipGroups)
  const [searchValue, setSearchValue] = useState<string>('')
  const [debouncedSearchValue] = useDebounceValue<string>(searchValue, 500)
  const [isMemberListModalOpen, setIsMemberListModalOpen] =
    useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isTreeModalOpen, setIsTreeModalOpen] = useState<boolean>(false)
  const confirm = useConfirm()
  const isMobile = useMediaQuery('(max-width: 640px)')

  useEffect(() => {
    if (debouncedSearchValue !== '') {
      const filteredGroups = membershipGroups.filter((group) =>
        group.name.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
      )
      setFilteredGroups(filteredGroups)
    } else {
      setFilteredGroups(membershipGroups)
    }
  }, [debouncedSearchValue])

  useEffect(() => {
    if (membershipGroups) {
      if (debouncedSearchValue !== '') {
        const filteredGroups = membershipGroups.filter((group) =>
          group.name.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
        )
        setFilteredGroups(filteredGroups)
      } else {
        setFilteredGroups(membershipGroups)
      }
    }
  }, [membershipGroups])

  const [bulkUpdate] = useMutation<
    BulkUpsertMembershipsToMembershipGroup,
    BulkUpsertMembershipsToMembershipGroupVariables
  >(BULK_UPSERT_MEMBERSHIPS_TO_MEMBERSHIP_GROUP, {
    onCompleted: () => {
      toast.success('Group Members Updated', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['FindUserTableGroupsQuery'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const [updateGroup] = useMutation<
    UpdateMembershipGroup,
    UpdateMembershipGroupVariables
  >(UPDATE_MEMBERSHIP_GROUP, {
    onCompleted: () => {
      toast.success('Group Updated', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['FindUserTableGroupsQuery'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const [createGroup] = useMutation<
    CreateMembershipGroup,
    CreateMembershipGroupVariables
  >(CREATE_MEMBERSHIP_GROUP, {
    onCompleted: () => {
      toast.success('Group Created', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['FindUserTableGroupsQuery'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const [deleteGroup] = useMutation<
    DeleteMembershipGroup,
    DeleteMembershipGroupVariables
  >(DELETE_MEMBERSHIP_GROUP, {
    onCompleted: () => {
      toast.success('Group Deleted', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['FindUserTableGroupsQuery'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const createOrUpdateGroup = async (group: membershipGroupInput) => {
    const { id, name, parentGroupId } = group

    if (id) {
      await updateGroup({
        variables: {
          id: id,
          input: {
            name,
            parentGroupId,
          },
        },
      })
    } else {
      await createGroup({
        variables: {
          input: {
            name,
            parentGroupId,
          },
        },
      })
    }
  }

  return (
    <div className={'w-full p-4 md:p-10'}>
      {isTreeModalOpen && (
        <GroupTreeView
          selectedGroup={selectedGroup}
          isOpen={isTreeModalOpen}
          onClose={() => {
            setIsTreeModalOpen(false)
            setSelectedGroup(null)
          }}
          membershipGroups={membershipGroups}
        />
      )}
      {isEditModalOpen && (
        <GroupEditForm
          group={selectedGroup}
          onManageMembers={() => setIsMemberListModalOpen(true)}
          isOpen={isEditModalOpen}
          groupList={membershipGroups}
          onComplete={(data) => {
            createOrUpdateGroup(data)
            setIsEditModalOpen(false)
            setSelectedGroup(null)
          }}
          onCancel={() => {
            setIsEditModalOpen(false)
            setSelectedGroup(null)
          }}
        />
      )}
      {isMemberListModalOpen && (
        <MemberListModal
          isOpen={isMemberListModalOpen}
          onClose={() => {
            setIsMemberListModalOpen(false)
          }}
          onComplete={(addIds, deleteIds) => {
            bulkUpdate({
              variables: {
                membershipIdsToRemove: deleteIds,
                membershipIdsToAdd: addIds,
                membershipGroupId: selectedGroup?.id,
              },
            })
          }}
          membershipGroup={selectedGroup}
          memberships={memberships}
        />
      )}

      <Grid container gap={4}>
        <Grid size={{ xs: 12 }}>
          <Stack spacing={1} direction={'row'} justifyContent={'flex-end'}>
            {!isMobile && (
              <Tooltip title={'View Tree'}>
                <IconButton
                  onClick={() => setIsTreeModalOpen(true)}
                  className={
                    'text-gray-500 hover:!bg-gray-300 hover:text-gray-600'
                  }
                >
                  <TreeView className={'h-6 w-6 fill-current'} />
                </IconButton>
              </Tooltip>
            )}

            <div className={'w-full md:w-[250px]'}>
              <SearchField
                value={searchValue}
                onChange={(value) => {
                  setSearchValue(value)
                }}
              />
            </div>

            <Button
              startIcon={<PlusIcon className={'h-4 w-4'} />}
              fullWidth={false}
              onClick={() => {
                setSelectedGroup(null)
                setIsEditModalOpen(true)
              }}
              size="small"
              buttonDataTestId="add-group-button"
              className="px-6"
            >
              Add {isMobile ? '' : ' Group'}
            </Button>
          </Stack>
        </Grid>

        {filteredGroups?.length > 0 && (
          <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(350px,1fr))] gap-4 md:pt-4">
            {filteredGroups.map((group) => (
              <GroupCard
                key={group.id + 'card'}
                membershipGroup={group}
                onViewTree={() => {
                  setSelectedGroup(group)
                  setIsTreeModalOpen(true)
                }}
                onManageMembers={() => {
                  setSelectedGroup(group)
                  setIsMemberListModalOpen(true)
                }}
                onDelete={(id) => {
                  confirm({
                    title: 'Delete Group?',
                    description: 'Are you sure you want to delete this Group?',
                  }).then(() => {
                    deleteGroup({ variables: { id } })
                  })
                }}
                onEdit={() => {
                  setSelectedGroup(group)
                  setIsEditModalOpen(true)
                }}
                isMobile={isMobile}
              />
            ))}
          </div>
        )}

        {filteredGroups?.length === 0 && (
          <Empty className="py-20" title={'No Groups Found'} />
        )}
      </Grid>
    </div>
  )
}

export default GroupManagement
