import { FC, useState } from 'react'

import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import { toast } from '@redwoodjs/web/toast'

import {
  MembershipGroup,
  ParentGroup,
} from 'src/components/GroupsDataGridCell/GroupsDataGridCell'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import Modal from 'src/components/Modal/Modal'

export interface membershipGroupInput {
  id?: number
  name: string
  parentGroupId: number | null
}

interface GroupEditFormProps {
  group: MembershipGroup
  onComplete: (data: membershipGroupInput) => void
  onCancel: () => void
  isOpen: boolean
  groupList: MembershipGroup[]
  onManageMembers: () => void
}

const GroupEditForm: FC<GroupEditFormProps> = ({
  group,
  onComplete,
  onCancel,
  isOpen,
  groupList,
  onManageMembers,
}) => {
  const [name, setName] = useState<string>(group?.name)
  const [parentGroupId, setParentGroupId] = useState<number>(
    group?.parentGroup?.id || null,
  )
  const [parentValue, setParentValue] = useState<ParentGroup>(
    group?.parentGroup || null,
  )
  const [parentInputValue, setParentInputValue] = useState('')
  return (
    <Modal
      open={isOpen}
      title={group ? 'Edit Group' : 'Add Group'}
      footerVisible
      onClose={onCancel}
      onCancel={onCancel}
      onConfirm={() => {
        if (name.length > 0) {
          onComplete({
            id: group?.id,
            name,
            parentGroupId,
          })
        } else {
          toast.error('Name cannot be empty')
        }
      }}
      dialogClassName={'max-w-[450px]'}
      childClassName={'p-4'}
      className="w-full"
    >
      <Stack spacing={3}>
        <TextField
          label={'Name'}
          defaultValue={group?.name}
          onChange={(event) => {
            setName(event.target.value)
          }}
          data-testid="group-name-input"
        />
        <Autocomplete
          options={groupList.filter((g) => g.id !== group?.id)}
          getOptionLabel={(option) => option.name}
          value={parentValue}
          onChange={(_, newValue: MembershipGroup | null) => {
            setParentValue(newValue)
            setParentGroupId(newValue.id)
          }}
          inputValue={parentInputValue}
          onInputChange={(_, newInputValue) => {
            setParentInputValue(newInputValue)
          }}
          renderInput={(params) => (
            <TextField {...params} label={'Parent Group'} />
          )}
        />
        {group && <Button onClick={onManageMembers}>Manage Members</Button>}
      </Stack>
    </Modal>
  )
}

export default GroupEditForm
