import { FC } from 'react'

import { stratify, tree } from 'd3-hierarchy'
import ReactFlow, {
  Controls,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'reactflow'

import GroupTreeCustomNodes from 'src/components/GroupManagement/GroupTreeCustomNodes'
import {
  narrowTree,
  NarrowTreeProps,
} from 'src/components/GroupManagement/util'
import { MembershipGroup } from 'src/components/GroupsDataGridCell/GroupsDataGridCell'
import { NodeType } from 'src/components/InformerOrgChart/InformerOrgChart'
import Modal from 'src/components/Modal/Modal'
import { useAuth } from 'src/Providers'

import 'reactflow/dist/style.css'

interface GroupTreeViewProps {
  isOpen: boolean
  onClose: () => void
  membershipGroups: MembershipGroup[]
  selectedGroup: MembershipGroup
}

const GroupTreeView: FC<GroupTreeViewProps> = ({
  isOpen,
  onClose,
  membershipGroups,
  selectedGroup,
}) => {
  const ORG_NODE_ID = 'ORG_NODE'
  const { currentUser } = useAuth()
  let curatedGroups = membershipGroups

  if (selectedGroup) {
    const props: NarrowTreeProps = {
      selectedGroup: {
        ...selectedGroup,
        parentGroup: selectedGroup.parentGroup,
      },
      groups: membershipGroups,
      isStart: true,
    }
    curatedGroups = narrowTree(props)
  }

  const organisationName = currentUser.parentData.name // This is used to fill the text with the organisation node

  const organizationStructureNode = {
    id: ORG_NODE_ID,
    data: {
      position: organisationName,
      type: 'org',
      label: organisationName,
    },
    position: { x: 0, y: 0 },
    type: 'custom',
  }
  const returnEdgesFromData = (nodes) => {
    return nodes
      .map((node) => ({
        id: `e${node.id}-${node.data.parentGroupId || ORG_NODE_ID}`, // We need to create a unique ID for the edge
        source: node.data.parentGroupId
          ? node.data.parentGroupId?.toString()
          : ORG_NODE_ID, // If the person has a superior, we use that as the source, otherwise the seat reports to the organisation
        target: node?.id?.toString(),
        style: { strokeWidth: 3, stroke: '#3B3B3B' },
      }))
      .filter((edge) => edge !== null)
  }

  const returnHierarchyNodesFromData = (nodes, edges) => {
    if (!nodes || nodes.length === 0) {
      return [organizationStructureNode]
    }
    // We use d3 to create the hierarchy
    const hierarchy = stratify()
      .id((d: NodeType) => d.id)
      .parentId((d: NodeType) => edges.find((e) => e.target === d.id)?.source)([
      organizationStructureNode,
      ...nodes,
    ])
    // We use d3 to create the layout
    const layout = tree()
      .nodeSize([300, 200])
      .separation(() => 1)
    const root = layout(hierarchy)
    // We return the nodes with the positions
    return [organizationStructureNode, ...nodes].map((node) => {
      const { x, y } = root.find((d) => d.id === node.id) || node.position
      return { ...node, position: { x, y } }
    })
  }

  const membershipNodes = curatedGroups.map((group) => ({
    id: group.id.toString(),
    data: {
      parentGroupId: group.parentGroup?.id,
      label: group.name,
    },
    type: 'custom',
  }))

  const structureEdgesFromData = returnEdgesFromData(membershipNodes) // Get the edges from the structure nodes

  const newStructure = returnHierarchyNodesFromData(
    membershipNodes,
    structureEdgesFromData,
  )

  const nodeTypes = {
    custom: GroupTreeCustomNodes,
  }
  const [nodes] = useNodesState(newStructure)
  const [edges] = useEdgesState(structureEdgesFromData)

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        dialogClassName={'max-w-[800px] h-[800px] bg-red-500 overflow-hidden'}
        title={'Tree View'}
      >
        <div className={'w-[800px] h-[700px]'}>
          <ReactFlowProvider>
            <ReactFlow
              defaultViewport={{ x: 0, y: 0, zoom: 0.1 }}
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              edgesUpdatable={false}
              edgesFocusable={false}
              nodesDraggable={false}
              nodesConnectable={false}
              nodesFocusable={false}
              elementsSelectable={false}
              proOptions={{ hideAttribution: true }}
              fitView
            >
              <Controls />
            </ReactFlow>
          </ReactFlowProvider>
        </div>
      </Modal>
    </div>
  )
}

export default GroupTreeView
