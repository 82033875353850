import React, { FC, useEffect, useState } from 'react'

import { PlusIcon } from '@heroicons/react/24/solid'
import { Chip } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { DataGrid, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid'

import {
  Membership,
  MembershipGroup,
} from 'src/components/GroupsDataGridCell/GroupsDataGridCell'
import Button from 'src/components/Library/Button/Button'
import Tab from 'src/components/Library/Tab/Tab'
import Tabs from 'src/components/Library/Tabs/Tabs'
import { mapUsersData } from 'src/components/MemberManagement/MemberManagement.mapper'
import Modal from 'src/components/Modal/Modal'

interface MemberListModalProps {
  isOpen: boolean
  onClose: () => void
  memberships: Membership[]
  membershipGroup: MembershipGroup
  onComplete: (addIds: number[], removeIds: number[]) => void
}

enum FilterTabs {
  ALL_MEMBERS = 'All Members',
  GROUP_MEMBERS = 'Group Members',
}

const MemberListModal: FC<MemberListModalProps> = ({
  isOpen,
  onClose,
  memberships,
  membershipGroup,
  onComplete,
}) => {
  const [userData, setUserData] = useState(mapUsersData(memberships))
  const [removedMembers, setRemovedMembers] = useState<Membership[]>([])
  const [addedMembers, setAddedMembers] = useState<Membership[]>([])
  const [filteredMembershipGroup, setFilteredMembershipGroup] =
    useState(membershipGroup)
  const [membersInGroup, setMembersInGroup] = useState(
    filteredMembershipGroup?.memberships.map(({ membership }) => membership),
  )
  const [filter, setFilter] = useState<string>(FilterTabs.ALL_MEMBERS)

  useEffect(() => {
    if (filter === FilterTabs.ALL_MEMBERS) {
      setUserData(mapUsersData(memberships))
    } else if (filter === FilterTabs.GROUP_MEMBERS) {
      setUserData(
        mapUsersData(memberships).filter((user) =>
          membersInGroup.find((member) => member.id === user.id),
        ),
      )
    }
  }, [filter, memberships, membersInGroup, membershipGroup])

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'User',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={'flex flex-row gap-2 items-center'}>
            <Avatar alt={params.row.name} src={params.row.avatarUrl} />
            <p className={'text-gray-800'}>{params.row.name}</p>
          </div>
        )
      },
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      sortable: false,
      getActions: (params) => {
        const isMemberInGroup = membersInGroup.find(
          (group) => group.id === params.row.id,
        )

        if (isMemberInGroup) {
          return [
            <Button
              key={params.row.id + 'remove'}
              fullWidth={false}
              onClick={() => {
                const addedMembersIds = addedMembers.map((member) => member.id)
                setMembersInGroup(
                  membersInGroup.filter(
                    (member) => member.id !== params.row.id,
                  ),
                )
                if (addedMembersIds.includes(params.row.id)) {
                  setAddedMembers(
                    addedMembers.filter(
                      (member) => member.id !== params.row.id,
                    ),
                  )
                }
                setRemovedMembers([...removedMembers, params.row])
              }}
              color={'secondary'}
              variant={'text'}
            >
              Remove
            </Button>,
          ]
        } else {
          return [
            <Button
              key={params.row.id + 'add'}
              size={'small'}
              fullWidth={false}
              onClick={() => {
                const removedMembersIds = removedMembers.map(
                  (member) => member.id,
                )
                setMembersInGroup([...membersInGroup, params.row])
                if (removedMembersIds.includes(params.row.id)) {
                  setRemovedMembers(
                    removedMembers.filter(
                      (member) => member.id !== params.row.id,
                    ),
                  )
                }
                setAddedMembers([...addedMembers, params.row])
              }}
              startIcon={<PlusIcon className={'w-4 h-4'} />}
              variant={'outlined'}
            >
              Add
            </Button>,
          ]
        }
      },
    },
  ]

  return (
    <Modal
      open={isOpen}
      title={'Manage Group Members'}
      footerVisible={true}
      onClose={onClose}
      onCancel={onClose}
      dialogClassName={'max-w-[450px] overflow-hidden'}
      className="w-full"
      confirmText={'Done'}
      onConfirm={() => {
        onComplete(
          addedMembers.map((member) => member.id),
          removedMembers.map((member) => member.id),
        )
        onClose()
      }}
      childClassName={'mt-0'}
    >
      <div className={'mb-2'}>
        <Tabs
          value={filter}
          onChange={(_, newValue: string) => {
            setFilter(newValue)
          }}
        >
          <Tab label={FilterTabs.ALL_MEMBERS} value={FilterTabs.ALL_MEMBERS} />
          <Tab
            label={FilterTabs.GROUP_MEMBERS}
            value={FilterTabs.GROUP_MEMBERS}
            iconPosition="end"
            icon={<Chip label={membersInGroup.length} size={'small'} />}
          />
        </Tabs>
      </div>
      <div className={'h-[600px]'}>
        <DataGrid<(typeof userData)[0]>
          sx={{
            '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            border: '0',
          }}
          columnHeaderHeight={0}
          slots={{
            footer: () => null,
            toolbar: () => (
              <GridToolbarQuickFilter
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                variant={'outlined'}
                size={'small'}
                data-testid="member-management-group-search"
                className={'mx-2 mb-2'}
              />
            ),
          }}
          columns={columns}
          rows={userData}
          hideFooterPagination
          hideFooterSelectedRowCount
          disableRowSelectionOnClick
        />
      </div>
    </Modal>
  )
}

export default MemberListModal
