import { FC } from 'react'

import {
  FindUserTableGroupsQuery,
  FindUserTableGroupsQueryVariables,
} from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import GroupManagement from 'src/components/GroupManagement/GroupManagement'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

export const QUERY = gql`
  query FindUserTableGroupsQuery {
    membershipGroups: membershipGroupsByClient {
      id
      clientId
      name
      parentGroup {
        id
        name
      }
      childGroup {
        id
        name
      }
      memberships {
        membership {
          id
          user {
            id
            name
            avatarUrl
          }
          isActive
        }
      }
    }
    memberships: membershipsByClient(activeOnly: true) {
      id
      role
      memberPositions {
        id
        name
      }
      user {
        id
        name
        email
        avatarUrl
        position
      }
    }
  }
`

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'network-only',
  nextFetchPolicy: 'network-only',
})

export const Loading = () => <LoadingSpinner />

export const Empty: FC<CellSuccessProps> = ({
  membershipGroups,
  memberships,
}) => (
  <GroupManagement
    membershipGroups={membershipGroups}
    memberships={memberships}
  />
)

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

type Props = CellSuccessProps<
  FindUserTableGroupsQuery,
  FindUserTableGroupsQueryVariables
>
export type MembershipGroup = Props['membershipGroups'][0]
export type Membership = Props['memberships'][0]
export type ParentGroup = MembershipGroup['parentGroup']

export const Success: FC<Props> = ({ membershipGroups, memberships }) => {
  return (
    <>
      <GroupManagement
        membershipGroups={membershipGroups}
        memberships={memberships}
      />
    </>
  )
}
