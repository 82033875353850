import { FC } from 'react'

import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { AvatarGroup, CardContent, Grid2 as Grid } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'

import { MembershipGroup } from 'src/components/GroupsDataGridCell/GroupsDataGridCell'
import Button from 'src/components/Library/Button/Button'
import IconButton from 'src/components/Library/IconButton'
import { TreeView } from 'src/lib/svg/SvgComponents'

interface GroupCardProps {
  membershipGroup: MembershipGroup
  onManageMembers: () => void
  onEdit: (id: number) => void
  onDelete: (id: number) => void
  onViewTree: (membershipGroup: MembershipGroup) => void
  isMobile: boolean
}

const GroupCard: FC<GroupCardProps> = ({
  membershipGroup,
  onManageMembers,
  onEdit,
  onDelete,
  onViewTree,
  isMobile,
}) => {
  return (
    <div className={'h-full'}>
      <Card
        elevation={0}
        className={'flex h-full w-full flex-col justify-between rounded-lg'}
        variant={'outlined'}
      >
        <CardHeader
          avatar={
            <Avatar src={''} alt={membershipGroup.name}>
              {membershipGroup.name[0]}
            </Avatar>
          }
          action={
            <Stack direction={'row'} spacing={1} className={'mt-2'}>
              {!isMobile && (
                <IconButton
                  size={'small'}
                  className={
                    'text-gray-400 hover:!bg-gray-300 hover:text-gray-600'
                  }
                  onClick={() => {
                    onViewTree(membershipGroup)
                  }}
                >
                  <TreeView className={'h-5 w-5 fill-current'} />
                </IconButton>
              )}

              <IconButton
                size={'small'}
                className={
                  'text-gray-400 hover:!bg-gray-300 hover:text-gray-600'
                }
                onClick={() => {
                  onEdit(membershipGroup.id)
                }}
              >
                <PencilSquareIcon className="h-5 w-5 stroke-2" />
              </IconButton>
              <IconButton
                size={'small'}
                className={'hover:!bg-gray-300'}
                color={'error'}
                onClick={() => {
                  onDelete(membershipGroup.id)
                }}
              >
                <TrashIcon className="h-5 w-5 stroke-2" />
              </IconButton>
            </Stack>
          }
          title={
            <h3
              className="max-w-[140px] text-base leading-5 text-gray-800"
              data-testid={`group-title-${membershipGroup.name}`}
            >
              {membershipGroup.name}
            </h3>
          }
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid size={{ xs: 6 }}>
              <Stack>
                <p className={'text-xs font-normal text-gray-400'}>
                  Parent Group
                </p>
                <p
                  className={`font-medium ${
                    membershipGroup.parentGroup?.name
                      ? 'text-sm text-gray-800'
                      : 'text-xs italic text-gray-400'
                  }`}
                >
                  {membershipGroup.parentGroup?.name ?? 'None'}
                </p>
              </Stack>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <div className={'flex justify-end'}>
                {membershipGroup.memberships.length === 0 ? (
                  <Stack spacing={1} justifyContent="flex-end">
                    <div
                      className={'text-right text-xs font-normal text-gray-400'}
                    >
                      No Members
                    </div>
                    <Button
                      variant={'outlined'}
                      fullWidth={false}
                      className={'rounded-full p-2 py-1'}
                      onClick={onManageMembers}
                    >
                      Add members
                    </Button>
                  </Stack>
                ) : (
                  <Button
                    variant={'text'}
                    fullWidth={false}
                    className={'min-w-[0] rounded-full p-1'}
                    onClick={onManageMembers}
                  >
                    <AvatarGroup
                      total={
                        membershipGroup.memberships.filter(
                          ({ membership }) => membership.isActive,
                        ).length
                      }
                      componentsProps={{
                        additionalAvatar: {
                          sx: {
                            width: 35,
                            height: 35,
                            bgcolor: '#818cf8',
                            zIndex: 2,
                          },
                        },
                      }}
                      max={4}
                    >
                      {membershipGroup.memberships
                        .filter(({ membership }) => membership.isActive)
                        .map(({ membership }) => (
                          <Avatar
                            key={membership.id}
                            sx={{
                              width: 35,
                              height: 35,
                            }}
                            alt={membership?.user?.name}
                            src={membership?.user?.avatarUrl}
                          ></Avatar>
                        ))}
                    </AvatarGroup>
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default GroupCard
